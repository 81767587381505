import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '2023MBA非全2班班级系统',
    },
  },
  {
    name: 'news',
    path: '/news',
    component: () => import('./view/news/list'),
    meta: {
      title: '消息列表',
    },
  },
  {
    name: 'newsDetail',
    path: '/news/detail',
    component: () => import('./view/news/detail'),
    meta: {
      title: '消息详情',
    },
    
  },
  {
    name: 'photo',
    path: '/photo',
    component: () => import('./view/photo/index'),
    meta: {
      title: '相册',
    },
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user/index'),
    meta: {
      title: '用户',
    },
    children:[
      {
        name: 'todo',
        path: 'todo',
        component: () => import('./view/user/todo'),
        meta: {
          title: '待办事项',
        },
      }
    ],
  },
  {
    name: 'schedule',
    path: '/schedule',
    component: () => import('./view/schedule/index'),
    meta: {
      title: '日程',
    },
  },
  {
    name: 'taqing2024',
    path: '/taqing2024',
    component: () => import('./view/single/taqing2024'),
    meta: {
      title: '一起去踏青~~~',
    },
  },
  {
    name: 'op',
    path: '/op',
    component: () => import('./view/single/op'),
    meta: {
      title: '饼干',
    },
  },
  {
    name: 'test',
    path: '/test',
    component: () => import('./view/test/index'),
    meta: {
      title: '测试',
    },
  },
  {
    name: 'subject',
    path: '/subject',
    component: () => import('./view/subject/index'),
    meta: {
      title: '沟通风格测评',
    },
  }
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
