import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { ref } from 'vue';
import axios from 'axios';
// import pinia from './stores'
import { createPinia } from "pinia"
import piniaPersist from 'pinia-plugin-persist'

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPersist)

if (process.env.NODE_ENV === 'development') {
   axios.defaults.baseURL = 'https://localhost:10001'
}
 else{
   axios.defaults.baseURL = 'http://www.ecustmba2023.site/api'
   //axios.defaults.baseURL = 'http://preview.ecustmba2023.site/api'
}
 

// 全局挂载 axios
app.config.globalProperties.$axios = axios
app.config.globalProperties.$router = router
app.provide('$axios',axios);
app.use(pinia);
app.use(router);
app.mount('#app');
